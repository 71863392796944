<template>
  <div>
    <b-modal
      ref="modal-customer-companies"
      scrollable
      :no-close-on-backdrop="true"
      ok-only
      ok-variant="secondary"
    >
      <template #modal-header="{}">
        <div class="d-flex align-items-center justify-content-between w-100">
          <h6 class="mb-0">Organizations associated with this domain</h6>
        </div>
      </template>
      <div class="w-100">
        <div class="w-100 d-flex align-items-center justify-content-center">
          <p>
            Domain:
            <span class="ml-25 font-weight-bold">{{
              parseDomain(emailid)
            }}</span>
          </p>
        </div>
        <ul v-if="customerCompanies != null && customerCompanies.length > 0">
          <li v-for="comp in customerCompanies" :key="comp._id">
            {{ comp.name }}
          </li>
        </ul>
        <div
          v-else
          class="w-100 d-flex flex-column align-items-center justify-content-center"
          style="height: 220px"
        >
          <feather-icon icon="SlashIcon" class="mb-1" size="32" />
          <h5 class="font-weight-bolder">No Organizations</h5>
        </div>
      </div>
      <template #modal-footer>
        <div
          class="w-100 d-flex align-items-center justify-content-center mt-75"
        >
          <b-button
            variant="success"
            size="sm"
            class="mr-50"
            @click="
              () => {
                $refs['modal-customer-companies'].hide();
              }
            "
            >Continue</b-button
          >
          <b-button
            variant="danger"
            size="sm"
            @click="
              () => {
                $refs['modal-customer-companies'].hide();
                closeAdduser();
              }
            "
            >Cancel</b-button
          >
        </div>
      </template>
    </b-modal>
    <div
      class="card p-2 bg-card"
      v-if="this.$store.state.auth.permissions.includes('customer-list')"
    >
      <div class="row">
        <div class="col-12 mb-1">
          <div class="d-flex justify-content-between align-items-center">
            <input
              v-model="searchCustomers"
              placeholder="Search"
              class="mr-bottom form-control input-sm mr-1 ml-1 mt-1"
              style="max-width: 200px"
            />
            <div>
              <button
                class="btn btn-primary btn-sm float-right mr-1 mt-1"
                @click="openAddUser()"
                :disabled="
                  !this.$store.state.auth.permissions.includes('customer-store')
                "
              >
                + Add
              </button>
              <button
                class="btn btn-primary btn-sm float-right mt-1 mr-1"
                @click="openmergeTenants"
              >
                Merge Tenants
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card p-2"
      v-if="this.$store.state.auth.permissions.includes('customer-list')"
    >
      <table class="table border mb-1">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Company</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(company, index) in companies" :key="company._id">
            <td scope="row">{{ (currentPage - 1) * perPage + index + 1 }}</td>
            <td>
              <b-avatar
                variant="primary"
                :text="company.name.charAt(0)"
                class="mr-50"
              />{{ company.name }}
            </td>
            <td>
              <button
                class="btn btn-primary btn-sm mr-1"
                @click="viewDetails(company._id)"
              >
                View Details
              </button>
              <button
                class="btn btn-warning btn-sm mr-1"
                @click="addSubsidiary(company._id)"
                v-if="!company.parent_id"
              >
                Add Associated Organization
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row">
        <div class="col-12 float-right">
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="lastPage * perPage"
            class="float-right"
          />
        </div>
      </div>

      <b-modal ref="modal-companydetails" title="Company Details" hide-footer>
        <h5 class="mb-1">
          <b>{{ companydetails.name }}</b>
        </h5>
        <div
          class="d-flex justify-content-between"
          v-for="user in companyusers"
          :key="user._id"
        >
          <p>{{ user.firstname }} {{ "" }} {{ user.lastname }}</p>
          <p v-for="role in user.roles" :key="role._id">{{ role.name }}</p>
          <span
            @click="openremoveuser(user.user_id)"
            class="icon-box-new"
            v-if="user.type == 'GRC_user'"
          >
            <feather-icon
              class="text-danger pointer rotate"
              icon="Trash2Icon"
              size="20"
            />
          </span>
        </div>
      </b-modal>
      <b-modal ref="modal-adduser" title="Assign VM User " hide-footer>
        <label for="">User</label>
        <select name="" id="" v-model="selecteduser" class="form-control">
          <option
            :value="user.user_id"
            v-for="user in this.assignusers"
            :key="user._id"
          >
            {{ user.firstname }} {{ user.lastname }}
          </option>
        </select>

        <b-form-group label="Role" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="roles"
            :options="options"
            :aria-describedby="ariaDescribedby"
            name="flavour-1"
          ></b-form-checkbox-group>
        </b-form-group>
        <!-- {{ selecteduser }} -->

        <button class="btn btn-primary btn-sm mt-1" @click="assignuser()">
          Assign User
        </button>
      </b-modal>
      <b-sidebar
        id="add-user"
        bg-variant="white"
        right
        backdrop
        shadow
        width="640px"
        v-model="add_user"
        lazy
      >
        <b-overlay
          opacity="0.17"
          blur="1rem"
          :show="showAssignUserOverlay"
          rounded="md"
          variant="secondary"
        >
          <div class="p-2">
            <validation-observer ref="observer">
              <b-form-group label="Company Name" label-for="basicInput">
                <validation-provider
                  #default="{ errors }"
                  name="Company"
                  rules="required"
                >
                  <b-form-input
                    id="basicInput"
                    placeholder="Company Name"
                    v-model="companyname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="First Name" label-for="basicInput">
                <validation-provider
                  #default="{ errors }"
                  name="First name"
                  rules="required"
                >
                  <b-form-input
                    id="basicInput"
                    placeholder="First Name"
                    v-model="fname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Last Name" label-for="basicInput">
                <validation-provider
                  #default="{ errors }"
                  name="Last name"
                  rules="required"
                >
                  <b-form-input
                    id="basicInput"
                    placeholder="Last Name"
                    v-model="lname"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Email" label-for="basicInput">
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="basicInput"
                    placeholder="Email"
                    @update="handleEmailChange"
                    v-model="emailid"
                    :debounce="900"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Phone Number" label-for="basicInput">
                <validation-provider
                  #default="{ errors }"
                  name="Phone number"
                  rules="required|integer"
                >
                  <b-form-input
                    id="basicInput"
                    placeholder="Phone Number"
                    v-model="phonenumber"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Country" label-for="basicInput">
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <!-- <b-form-input
                  id="basicInput"
                  placeholder="Country"
                  v-model="country"
                /> -->
                  <select name="" id="" class="form-control" v-model="country">
                    <option :value="ct.name" v-for="ct in countries">
                      {{ ct.name }}
                    </option>
                  </select>
                  <!-- {{ countries }} -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Industry" label-for="basicInput">
                <validation-provider
                  #default="{ errors }"
                  name="Industry"
                  rules="required"
                >
                  <!-- <b-form-input
                  id="basicInput"
                  placeholder="Industry"
                  v-model="industry"
                /> -->
                  <select name="" id="" class="form-control" v-model="industry">
                    <option :value="indus.name" v-for="indus in industries">
                      {{ indus.name }}
                    </option>
                  </select>
                  <!-- {{  industries }} -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Modules">
                <!-- <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="selected"
                :options="optionmodule"
                name="flavour-1"
                class="demo-inline-spacing"
              />
            </b-form-group> -->
                <span v-for="item in this.modules" :key="item._id">
                  <input
                    type="checkbox"
                    :value="item._id"
                    v-model="selectedModules"
                  />
                  <span class="checkbox-label"> {{ item.name }} </span> <br />
                </span>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox v-model="should_invite">
                  Send invitation to mail
                </b-form-checkbox>
              </b-form-group>

              <button
                class="btn btn-primary mt-1 mr-1"
                @click.prevent="validationForm"
                :disabled="disable_btn"
              >
                <b-spinner small v-if="disable_btn" />
                Add
              </button>
              <button class="btn btn-secondary mt-1" @click="closeAdduser()">
                Cancel
              </button>
            </validation-observer>
          </div>
        </b-overlay>
      </b-sidebar>
      <b-sidebar
        id="add-sub"
        bg-variant="white"
        right
        backdrop
        shadow
        width="640px"
        v-model="add_sub"
        lazy
      >
        <div class="p-2">
          <validation-observer ref="observer">
            <b-form-group label="Company Name" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="Company"
                rules="required"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Company Name"
                  v-model="companyname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="First Name" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="First name"
                rules="required"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="First Name"
                  v-model="fname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Last Name" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="Last name"
                rules="required"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Last Name"
                  v-model="lname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Email"
                  v-model="emailid"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Phone Number" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="Phone number"
                rules="required|integer"
              >
                <b-form-input
                  id="basicInput"
                  placeholder="Phone Number"
                  v-model="phonenumber"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Country" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <!-- <b-form-input
                  id="basicInput"
                  placeholder="Country"
                  v-model="country"
                /> -->
                <select name="" id="" class="form-control" v-model="country">
                  <option :value="ct.name" v-for="ct in countries">
                    {{ ct.name }}
                  </option>
                </select>
                <!-- {{ countries }} -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Industry" label-for="basicInput">
              <validation-provider
                #default="{ errors }"
                name="Industry"
                rules="required"
              >
                <!-- <b-form-input
                  id="basicInput"
                  placeholder="Industry"
                  v-model="industry"
                /> -->
                <select name="" id="" class="form-control" v-model="industry">
                  <option :value="indus.name" v-for="indus in industries">
                    {{ indus.name }}
                  </option>
                </select>
                <!-- {{  industries }} -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Modules">
              <!-- <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="selected"
                :options="optionmodule"
                name="flavour-1"
                class="demo-inline-spacing"
              />
            </b-form-group> -->
              <span v-for="item in this.modules" :key="item._id">
                <input
                  type="checkbox"
                  :value="item._id"
                  v-model="selectedModules"
                />
                <span class="checkbox-label"> {{ item.name }} </span> <br />
              </span>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="should_invite">
                Send invitation to mail
              </b-form-checkbox>
            </b-form-group>

            <button
              class="btn btn-primary mt-1 mr-1"
              @click.prevent="validationFormsub"
              :disabled="disable_btn"
            >
              <b-spinner small v-if="disable_btn" />
              Add Subsidiary
            </button>
            <button class="btn btn-secondary mt-1" @click="closeAdduser()">
              Cancel
            </button>
          </validation-observer>
        </div>
      </b-sidebar>
    </div>
    <div
      class="card p-2 d-flex align-items-center justify-content-center"
      v-else
    >
      <p>You don't have the permission to view this page</p>
    </div>
    <b-modal ref="merge-tenant" title="Merge Tenants" hide-footer>
      <label for="">Original Tenants</label>
      <select name="" id="" class="form-control" v-model="original_tenant">
        <option
          :value="original._id"
          v-for="original in companies_list"
          :key="original._id"
        >
          {{ original.name }}
        </option>
      </select>
      <label for="">Duplicate Tenants</label>
      <select name="" id="" class="form-control" v-model="duplicate_tenant">
        <option
          :value="duplicate._id"
          v-for="duplicate in companies_list"
          :key="duplicate._id"
        >
          {{ duplicate.name }}
        </option>
      </select>
      <button class="btn btn-primary mt-1 btn-sm" @click="mergeTenants">
        Merge Tenants
      </button>
    </b-modal>
  </div>
</template>
<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BFormGroup,
  BFormCheckboxGroup,
  BCardText,
  BFormCheckbox,
  BPagination,
  BSidebar,
  BFormInput,
  BAvatar,
  BSpinner,
  BButton,
  BOverlay,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import ResponseMixins from "../../mixins/ResponseMixins";
import CustomerMixins from "../../mixins/CustomerMixins";

export default {
  mixins: [ResponseMixins, CustomerMixins],
  data() {
    return {
      companies: [],
      companydetails: [],
      tenantid: "",
      companyusers: [],
      assignusers: [],
      optionuser: [],
      selecteduser: "",
      roles: [],
      options: [
        { text: "Admin", value: "admin" },
        { text: "User", value: "user" },
        { text: "CISO", value: "ciso" },
        { text: "VM Consultant", value: "vm_consultant" },
      ],
      currentPage: 1,
      lastPage: 1,
      perPage: 10,
      required,
      email,
      fname: "",
      lname: "",
      companyname: "",
      emailid: "",
      phonenumber: "",
      should_invite: "",
      add_user: false,
      searchCustomers: "",
      parentCompanyId: "",
      add_sub: false,
      modules: [],
      optionmodule: [],
      selectedModules: [],
      country: "",
      industry: "",
      countries: [],
      industries: [],
      disable_btn: false,
      original_tenant: "",
      duplicate_tenant: "",
      companies_list: [],
      customerCompanies: [],
      showAssignUserOverlay: false,
    };
  },
  components: {
    vSelect,
    BFormGroup,
    BFormCheckboxGroup,
    BCardText,
    BFormCheckbox,
    BPagination,
    BSidebar,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BAvatar,
    BSpinner,
    BButton,
    BOverlay,
  },
  watch: {
    currentPage(value) {
      this.getCompanies(value);
    },
    searchCustomers() {
      this.getCompanies(this.currentPage);
    },
  },
  mounted() {
    this.getCompanies(this.currentPage);
    this.getUsers();
    this.getModules();
    this.getCountries();
    this.getIndustries();
    this.getCompanieslist();
  },
  methods: {
    parseDomain(email) {
      const atIndex = email.indexOf("@");
      return atIndex !== -1 ? email.slice(atIndex + 1) : "";
    },
    handleEmailChange(value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(value);

      if (isValid) {
        this.getCustomerCompaniesAndSetData(value);
      }
    },

    handleHideCustomerCompaniesModal() {
      this.$refs["modal-customer-companies"].hide();
      this.customerCompanies = [];
    },

    getCustomerCompaniesAndSetData(email) {
      this.showAssignUserOverlay = true;
      this.getCustomerCompanies({ user_email: email, dont_paginate: true })
        .then((res) => {
          console.log(res);
          this.customerCompanies = res.data.data;
          this.$refs["modal-customer-companies"].show();
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showAssignUserOverlay = false;
        });
    },

    openmergeTenants() {
      this.$refs["merge-tenant"].show();
      this.original_tenant = "";
      this.duplicate_tenant = "";
    },
    mergeTenants() {
      const data = {
        original_tenant_id: this.original_tenant,
        duplicate_tenant_id: this.duplicate_tenant,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/companies/merge-duplicate`,
      };
      this.$http(options)
        .then((res) => {
          this.$refs["merge-tenant"].hide();
          this.getCompanies(this.currentPage);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          this.handleError(err);
        });
    },
    getCountries() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/countries`,
      };
      this.$http(options)
        .then((res) => {
          this.countries = res.data.data.data;

          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getIndustries() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/industries`,
      };
      this.$http(options)
        .then((res) => {
          this.industries = res.data.data.data;

          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getModules() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/modules`,
      };
      this.$http(options)
        .then((res) => {
          this.modules = res.data.data.data;
          this.optionmodule = [];
          for (let mod in this.modules) {
            let user = {
              text: mod.name,
              value: mod._id,
            };
            this.optionmodule.push(user);
          }

          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    addSubsidiary(id) {
      this.parentCompanyId = id;
      this.add_sub = true;
    },
    openAddUser() {
      this.add_user = true;
    },

    async closeAdduser() {
      this.add_user = false;
      this.fname = "";
      this.lname = "";
      this.companyname = "";
      this.emailid = "";
      this.phonenumber = "";
      this.should_invite = false;
      this.$nextTick(() => this.$refs.observer.reset());
    },

    validationForm() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.AddUser();
          this.disable_btn = true;
        }
      });
    },
    validationFormsub() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.disable_btn = true;
          this.AddSubcompany();
        }
      });
    },

    AddUser() {
      const data = {
        company: this.companyname,
        firstname: this.fname,
        lastname: this.lname,
        email: this.emailid,
        mobile: this.phonenumber,
        should_invite: this.should_invite,
        modules: this.selectedModules,
        country: this.country,
        industry: this.industry,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/customer/sign-up`,
      };
      this.$http(options)
        .then((res) => {
          this.disable_btn = false;
          this.companyname = "";
          this.fname = "";
          this.lname = "";
          this.emailid = "";
          this.phonenumber = "";
          this.should_invite = "";
          this.selectedModules = "";
          this.country = "";
          this.industry = "";
          this.closeAdduser();
          this.getCompanies(this.currentPage);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
    AddSubcompany() {
      const data = {
        company: this.companyname,
        firstname: this.fname,
        lastname: this.lname,
        email: this.emailid,
        mobile: this.phonenumber,
        should_invite: this.should_invite,
        parent_id: this.parentCompanyId,
        modules: this.selectedModules,
        country: this.country,
        industry: this.industry,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/customer/sign-up`,
      };
      this.$http(options)
        .then((res) => {
          this.disable_btn = false;
          this.add_sub = false;
          this.getCompanies(this.currentPage);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },

    openremoveuser(uid) {
      const data = {
        tenant_id: this.tenantid,
        user_id: uid,
        // roles: this.roles,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/customers/${this.tenantid}/remove_vm_user`,
      };
      this.$http(options)
        .then((res) => {
          this.$refs["modal-companydetails"].hide();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    assignuser() {
      const data = {
        tenant_id: this.tenantid,
        user_id: this.selecteduser,
        roles: this.roles,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/customers/${this.tenantid}/assign_vm_user`,
      };
      this.$http(options)
        .then((res) => {
          this.$refs["modal-adduser"].hide();
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getUsers() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/user/search`,
      };
      this.$http(options)
        .then((res) => {
          this.assignusers = res.data.data;
          for (let i in this.assignusers) {
            let user = {
              title: value,
            };
            this.optionuser.push(user);
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    assignUser(id) {
      this.viewDetailsCompany(id);
      this.$refs["modal-adduser"].show();
    },

    getCompanies(pageno) {
      const options = {
        method: "GET",
        params: { page: pageno, limit: this.perPage, include_children: "1" },
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_SSO_API_URL +
          `/companies?name=${this.searchCustomers}`,
      };
      this.$http(options)
        .then((res) => {
          this.companies = res.data.data.data;
          this.currentPage = res.data.data.current_page;
          this.lastPage = res.data.data.last_page;
          this.perPage = res.data.data.per_page;

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCompanieslist() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_SSO_API_URL + `/companies?dont_paginate=1`,
      };
      this.$http(options)
        .then((res) => {
          this.companies_list = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    viewDetails(id) {
      this.$router.push({ name: "customerdetails", params: { id: id } });
    },

    viewDetailsCompany(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/companies/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.companydetails = res.data.data.company;
          this.tenantid = res.data.data.company.tenant_uid;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    viewCompanyUsers(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/customers/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.companyusers = res.data.data.data.data;

          this.$refs["modal-companydetails"].show();

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
th {
  background: #472183 !important;
  color: #fff;
  text-transform: capitalize !important;
}
</style>

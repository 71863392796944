export default {
    methods: {
      getCustomerCompanies(params) {
        return new Promise((resolve, reject) => {
          const options = {
            method: "GET",
            url: `${process.env.VUE_APP_SSO_API_URL}/companies`,
            params: params,
          };
          this.$http(options)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
  
    
  
  
    },
  };
  